export const generateGradient = (
  color: string,
  steps: 4 | 5,
  angle: number
) => {
  const gradientSteps = {
    4: [
      [0.01, 0],
      [0.62, 62],
      [0.82, 82],
      [0.86, 86]
    ],

    5: [
      [0, 10],
      [0.2, 20],
      [0.5, 30],
      [0.67, 40],
      [0.8, 50],
      [1, 58]
    ]
  }

  const gradient = gradientSteps[steps].map(
    (i) => `rgba(${color}, ${i[0]}) ${i[1]}%`
  )

  return `linear-gradient(${angle}deg, ${gradient})`
}
