import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AudioBlock, AudioData, Lang, RootState } from '@client/types'
import {
  getEpisodeData,
  getEpisodeDataById,
  getPlayingEpisodeProps,
  getSavedProgress
} from '@selectors/audioPlayerSelectors'
import {
  rewindBackward,
  rewindForward,
  setPlaybackRate
} from '@store/AudioPlayer/audioPlayerActions'
import { formatTime } from '@utils/formatTime'
import { makeClassName } from '@utils/makeClassName'

import { SvgSymbol } from '@meduza/ui-kit-2'

import { BookmarkIcon } from '../../BookmarkIcon'
import { DownloadIcon } from '../../DownloadIcon'
import {
  AudioPanelTypes,
  AudioPlayerPlaybackRates,
  PlayerStatus,
  ProgressTypeEnums
} from '../constants'
import { PlayButton } from '../PlayButton'
import { Progress } from '../Progress'

import styles from './AudioPanel.module.css'

/*
 * Аудиопанель, которая вставляется в материал или блок
 */

interface AudioPanelProps {
  episodeUrl: string
  lang: Lang
  theme?: string
  styleContext: string
  withCover?: boolean
  type?: AudioPanelTypes
  block?: AudioBlock
  image?: string
  context?: string
  setOpen?: (open: boolean) => void
}

const getAudioFromBlock = (block: AudioBlock) => ({
  title: block.data.audio.title,
  mp3_url: block.data.audio.mp3_url,
  mp3_duration: block.data.audio.mp3_duration,
  cover_url: block.data.audio.image.cover_url,
  podcast: block.data.audio.podcast
})

export const AudioPanel: React.FC<AudioPanelProps> = ({
  episodeUrl,
  theme = 'default',
  styleContext,
  withCover = true,
  image,
  type = AudioPanelTypes.DEFAULT,
  block,
  setOpen
}) => {
  const dispatch = useDispatch()

  const defaultEpisodeProps = {
    data: {
      status: null,
      time: 0,
      playbackRate: 1
    }
  }

  const playingEpisodeProps = useSelector(getPlayingEpisodeProps)
  const byId = useSelector(getEpisodeDataById)

  const {
    data: { status, time, playbackRate }
  } =
    playingEpisodeProps ||
    (byId[episodeUrl] && { data: byId[episodeUrl] }) ||
    defaultEpisodeProps

  const episodeData = useSelector((state: RootState) =>
    getEpisodeData(state, { url: episodeUrl })
  )

  const {
    title,
    mp3_url: mp3Url,
    mp3_duration: duration,
    cover_url: coverUrl
  } =
    block && block.data && block.data.audio
      ? getAudioFromBlock(block)
      : episodeData && (episodeData.audio as AudioData)

  const savedProgress = useSelector((state: RootState) =>
    getSavedProgress(state, episodeUrl)
  )

  const handlePlaybackRateClick = () => {
    const playbackRateValues = Object.values(AudioPlayerPlaybackRates)
    const currentIndex = playbackRateValues.indexOf(playbackRate)

    const nextIndex =
      currentIndex + 1 === playbackRateValues.length ? 0 : currentIndex + 1

    if (status === PlayerStatus.PLAYING) {
      dispatch(
        setPlaybackRate({
          episodeUrl,
          playbackRate: playbackRateValues[nextIndex]
        })
      )
    }
  }

  const handleRewindBackwardClick = () => {
    if (status === PlayerStatus.PLAYING) {
      dispatch(rewindBackward({ episodeUrl }))
    }
  }

  const handleRewindForwardClick = () => {
    if (status === PlayerStatus.PLAYING) {
      dispatch(rewindForward({ episodeUrl }))
    }
  }

  const savedTime = status !== PlayerStatus.PLAYING && savedProgress * duration

  const playbackRateIcons: {
    pr: number
    icon:
      | 'speedHalf'
      | 'speedThreeQuarters'
      | 'speedOne'
      | 'speedOneQuarter'
      | 'speedOneHalf'
      | 'speedTwo'
  }[] = [
    { pr: 0.5, icon: 'speedHalf' },
    { pr: 0.75, icon: 'speedThreeQuarters' },
    { pr: 1, icon: 'speedOne' },
    { pr: 1.25, icon: 'speedOneQuarter' },
    { pr: 1.5, icon: 'speedOneHalf' },
    { pr: 2, icon: 'speedTwo' }
  ]

  const currentPlaybackRateIcon =
    playbackRateIcons.find((item) => item.pr === playbackRate) ||
    playbackRateIcons[2]

  // @ts-ignore
  const coverImage = coverUrl || episodeData?.image.cover_url || image

  const isEpisode = styleContext.includes('episode')

  return (
    <div
      className={makeClassName([
        [[styles.root], true],
        [styles[theme], !!theme && !!styles[theme]],
        [styles[styleContext], !!styleContext && !!styles[styleContext]],
        [styles.isInDynamicBlock, isEpisode]
      ])}
    >
      {withCover && coverImage && (
        <div
          className={styles.cover}
          style={{
            backgroundImage: `url("${coverImage}")`
          }}
        />
      )}
      <div className={styles.container}>
        {/* eslint-disable react/jsx-no-target-blank  */}
        <div
          className={makeClassName([
            [[styles.controls], true],
            [[styles.controlsInInstantPlayer], true]
          ])}
        >
          <button
            className={styles.button}
            onClick={handleRewindBackwardClick}
            type="button"
          >
            <SvgSymbol icon="backward" size="unset" />
          </button>

          <div
            className={makeClassName([
              [[styles.button], true],
              [[styles.buttonPlay], true],
              [[styles.buttonPlayInInstantPlayer], true]
            ])}
          >
            <PlayButton
              block={block}
              episodeUrl={episodeUrl}
              styleContext="isInInstantPlayer"
            />
          </div>

          <button
            className={styles.button}
            onClick={handleRewindForwardClick}
            type="button"
          >
            <SvgSymbol icon="forward" size="unset" />
          </button>
        </div>
        {/* eslint-enable react/jsx-no-target-blank  */}

        <div className={styles.title}>{title}</div>

        <div>
          <Progress
            episodeUrl={episodeUrl}
            duration={duration}
            type={ProgressTypeEnums.PANEL}
            theme={type === AudioPanelTypes.DEFAULT ? theme : 'default'}
            styleContext="isInAudioPanel"
          />
          <div className={styles.progressText}>
            <span>
              {/* eslint-disable-next-line no-nested-ternary */}
              {time
                ? formatTime(time)
                : savedTime
                ? formatTime(savedTime)
                : '00:00'}
            </span>
            <span>{formatTime(duration)}</span>
          </div>
        </div>

        <div className={styles.footerControlContainer}>
          <button
            className={makeClassName([
              [[styles.button], true],
              [[styles.buttonPlaybackRate], true]
            ])}
            onClick={handlePlaybackRateClick}
            type="button"
          >
            <SvgSymbol
              icon={currentPlaybackRateIcon.icon}
              size="unset"
              styleContext="isInAudioPanel"
            />
          </button>

          <div className={styles.advanceContainer}>
            <DownloadIcon theme="light" url={mp3Url} />
            <BookmarkIcon
              styleContext="isInAudioPanel"
              url={episodeUrl}
              setOpen={setOpen}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
