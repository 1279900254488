/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import { makeClassName } from '@client/utils/makeClassName'

import styles from './SupportPanel.module.css'

interface SupportPanelProps {
  lang: 'ru' | 'en'
}

const supportScheme = {
  ru: {
    href: 'https://support.meduza.io/?utm_source=meduza&utm_medium=button',
    text: 'Поддержать «Медузу»'
  },
  en: {
    href: 'https://support.meduza.io/en?utm_source=meduza_en&utm_medium=button',
    text: 'Support Meduza'
  }
}

export const SupportPanel: React.FC<SupportPanelProps> = ({ lang }) => {
  const { href, text } = supportScheme[lang]

  return (
    <div
      className={makeClassName([
        [styles.root, true],
        [styles[lang], true]
      ])}
    >
      <a className={styles.link} href={href} target="_blank">
        <span className={styles.text}>{text}</span>
      </a>
    </div>
  )
}
