import { useInView } from 'react-intersection-observer'

export const useBannerInView = () => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.5
  })

  return { ref, inView }
}
