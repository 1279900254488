import { useDispatch } from 'react-redux'
import { PopoverBanner } from '@client/components/Common/PopupBanner/PopupBanner.types'
import { BannerItem } from '@client/types'
import { BannerPosition } from '@client/types/bannerTypes'
import { trackBannerClick } from '@store/Analytics/analyticsActions'

// Обычная функция для аналитики bannerClick
export const sendBannerClickAnalytics = (
  banner: BannerItem | PopoverBanner,
  position: BannerPosition,
  dispatch: ReturnType<typeof useDispatch>
) => {
  if (typeof window === 'undefined') {
    return
  }

  const pathname = window.location.pathname
  const bannerId = banner.id || 'null'

  dispatch(
    trackBannerClick({
      pathname,
      bannerPosition: position,
      bannerId
    })
  )
}
