import React, { memo, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NativeBanner } from '@client/components/Common/NativeBanner'
import { getBanners } from '@client/selectors/appSelectors'
// import { getCurrentMaterialBanners } from '@client/selectors/materialsSelectors'
import { fetchBanners } from '@client/store/App/appActions'
import { BannersData } from '@client/types'
import { getBannersByPlatform } from '@client/utils/getBannersByPlatform'
import { getRandomInt } from '@client/utils/getRandomInt'

interface BannerContainerProps {
  lang: string
  isMaterial?: boolean
}

export const BannerContainerComponent = ({
  lang
}: // isMaterial
BannerContainerProps) => {
  const bannersData: BannersData | null = useSelector(getBanners)

  const dispatch = useDispatch()

  const banners = getBannersByPlatform(bannersData?.[lang])

  const random = getRandomInt(0, banners?.length - 1)
  const banner = banners?.[random]

  useEffect(() => {
    if (!bannersData) {
      dispatch(fetchBanners.request())
    }
  }, [bannersData, dispatch])

  return <NativeBanner banner={banner} />
}

export const BannerContainer = memo(BannerContainerComponent)

BannerContainer.whyDidYouRender = true
