export const DFPBannerData = {
  en: [
    {
      id: 'dfp-banner-1',
      href:
        'https://support.meduza.io/en?utm_source=meduza_en&utm_medium=right_banner&utm_campaign=240225',
      image:
        'https://meduza.io/image/attachments/images/011/104/741/original/1nYmwcp9z5sLJtiGW0btSA'
    }
  ],

  ru: [
    {
      id: 'dfp-ru-banner-1',
      href:
        'https://support.meduza.io/?utm_source=meduza&utm_medium=banner_aside&utm_campaign=ny2025',
      image:
        'https://meduza.io/image/attachments/images/010/738/970/original/hvIqkaE4j9uWaL7gradXhw'
    }
  ]
}
