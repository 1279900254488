import React from 'react'
import { useDispatch } from 'react-redux'
import { useBannerInView } from '@client/hooks/useBannerInView'
import { useBannerViewAnalytics } from '@client/hooks/useBannerViewAnalytics'
import { sendBannerClickAnalytics } from '@client/utils/sendBannerClickAnalytics'

import styles from './NativeBanner.module.css'

export const NativeBanner = ({ banner }) => {
  const bgColor = (banner && banner.bg) || '#000'

  const dispatch = useDispatch()

  const { ref, inView } = useBannerInView()

  const position = 'top'

  useBannerViewAnalytics({
    inView,
    banner,
    position,
    dispatch
  })

  const handleBannerClick = () => {
    sendBannerClickAnalytics(banner, position, dispatch)
  }

  if (!banner) {
    return <div></div>
  }

  return (
    <div className={styles.root} style={{ backgroundColor: bgColor }} ref={ref}>
      <a
        className={styles.block}
        href={banner.url}
        target="_blank"
        rel="noreferrer"
        onClick={handleBannerClick}
      >
        <picture className={styles.picture}>
          <source
            type="image/png"
            media="(max-width: 999px)"
            srcSet={banner.mobile}
          />

          <source
            type="image/png"
            media="(min-width: 1000px)"
            srcSet={banner.desktop}
          />

          <img
            src={
              'data:image/gif;base64,R0lGODlhAQABAPAAAPLy8gAAACH5BAAAAAAALAAAAAABAAEAAAICRAEAOw=='
            }
            alt={banner.alt}
          />
        </picture>
      </a>
    </div>
  )
}
