import React from 'react'
import { RichDynamicScreenDocument } from '@client/types'
import { Link } from '@common/Link'
import { RenderBlocks } from '@common/RenderBlocks'
import { useBannerTrack } from '@hooks/useBannerTrack'

import styles from './DynamicBlock.module.css'

interface DynamicBlockProps {
  block: RichDynamicScreenDocument
  origin: string
}

export const DynamicBlock: React.FC<DynamicBlockProps> = ({
  block,
  origin
}) => {
  const bannerTrackRef = useBannerTrack(block, origin)

  const { layout, url, blocks } = block

  // TODO: разобраться, почему переданный массив не работает => не применяются оба стиля в EpisodeCover
  // сейчас isInDynamicBlock применяется только в EpisodeCover, но в будущем все может поменяться

  const episodeTheme = block.theme || ''

  const dynamicBlocks = blocks.map((block) => (
    <RenderBlocks
      url={url}
      key={block.id}
      block={block}
      styleContext="isInDynamicBlock"
      theme={episodeTheme}
    />
  ))

  return (
    <article className={styles.root} ref={bannerTrackRef}>
      {layout === 'episode' ? (
        dynamicBlocks
      ) : (
        <Link to={`/${url}`} styleContext="isInBlockTitle">
          {dynamicBlocks}
        </Link>
      )}
    </article>
  )
}
