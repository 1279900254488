import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  BookLandingDocument,
  BriefDocument,
  DesktopBlock,
  EpisodeScreenDocument,
  ImageUrls,
  PodcastsListDocument,
  RichScreenDocument,
  ScreenDocument,
  ScreenDocumentWithMobile,
  ScreenRotationDocument,
  SimpleScreenDocument
} from '@client/types'
import { BriefBlock } from '@routes/Screen/BriefBlock'
import { BriefSubscriptionBlock } from '@routes/Screen/BriefSubscriptionBlock'
import { EpisodeBlock } from '@routes/Screen/EpisodeBlock'
import { PodcastListBlock } from '@routes/Screen/PodcastListBlock'
import { RichBlock } from '@routes/Screen/RichBlock'
import { SimpleBlock } from '@routes/Screen/SimpleBlock'
import { SupportBlock } from '@routes/Screen/SupportBlock'
import { TopicBlock } from '@routes/Screen/TopicBlock'
import {
  getScreenDocuments,
  getScreenRotationDocuments
} from '@selectors/screenDocumentsSelectors'
import { capitalize } from '@utils/capitalize'
import { makeClassName } from '@utils/makeClassName'
import { randomIntFromInterval } from '@utils/randomIntFromInterval'

import { BookLandingBlock } from '../BookLandingBlock'
import { CollectionBlock } from '../CollectionBlock'

import styles from './Section.module.css'

interface RenderBlockProps {
  desktopBlock: DesktopBlock
  correlation: keyof ImageUrls
  origin?: string
  screenDocuments: Record<string, ScreenDocumentWithMobile>
  currentUrl?: string
}

const RenderBlock: React.FC<RenderBlockProps> = ({
  desktopBlock,
  correlation,
  origin,
  screenDocuments,
  currentUrl
}) => {
  const screenRotationDocuments: Record<
    string,
    ScreenRotationDocument
  > = useSelector(getScreenRotationDocuments)

  const [isRendered, setIsRendered] = useState(false)

  useEffect(() => {
    setIsRendered(true)
  }, [])

  const blockClasses = [
    [styles.block, true],
    [styles[correlation], !!styles[correlation]],
    [styles.currentMaterial, currentUrl === desktopBlock.collection[0].key]
  ]
  const blockKey = `${desktopBlock.type}-${desktopBlock.published_at}`
  const blockType = `is${capitalize(desktopBlock.type)}`

  const block = screenDocuments[desktopBlock.collection[0].key]
  const url = desktopBlock.collection[0].key
  const calculatedOrigin =
    origin ||
    (desktopBlock.collection[0].origin && desktopBlock.collection[0].origin[0])

  switch (desktopBlock.type) {
    case 'simple': {
      return (
        <div
          key={`${blockKey}-${url}`}
          className={makeClassName(
            blockClasses.concat([[styles.isNews, true]])
          )}
        >
          <SimpleBlock
            block={block as SimpleScreenDocument}
            correlation={correlation}
          />
        </div>
      )
    }

    case 'rich':
    case 'card':
    case 'podcast': {
      return (
        <div
          key={`${blockKey}-${url}`}
          className={makeClassName(
            blockClasses.concat([[styles[blockType], !!styles[blockType]]])
          )}
        >
          <RichBlock
            block={block as RichScreenDocument}
            correlation={correlation}
            origin={calculatedOrigin!}
          />
        </div>
      )
    }

    case 'podcasts_list': {
      return (
        <div
          key={`${blockKey}-${url}`}
          className={makeClassName(
            blockClasses.concat([[styles[blockType], !!styles[blockType]]])
          )}
        >
          <PodcastListBlock
            block={block as PodcastsListDocument}
            origin={calculatedOrigin!}
          />
        </div>
      )
    }

    case 'episode': {
      return (
        <div
          key={`${blockKey}-${url}`}
          className={makeClassName(
            blockClasses.concat([[styles[blockType], !!styles[blockType]]])
          )}
        >
          <EpisodeBlock
            block={block as EpisodeScreenDocument}
            correlation={correlation}
            origin={calculatedOrigin!}
          />
        </div>
      )
    }
    case 'book_landing': {
      return (
        <div
          key={`${blockKey}-${url}`}
          className={makeClassName(
            blockClasses.concat([[styles[blockType], !!styles[blockType]]])
          )}
        >
          <BookLandingBlock
            block={block as BookLandingDocument}
            correlation={correlation}
            origin={calculatedOrigin!}
          />
        </div>
      )
    }

    case 'collection': {
      const urls = desktopBlock.collection.map((d) => d.key)
      const blocks = urls.map((url) => screenDocuments[url])

      return (
        <div
          key={blockKey}
          className={makeClassName(
            blockClasses.concat([[styles[blockType], !!styles[blockType]]])
          )}
        >
          <CollectionBlock
            blocks={blocks as ScreenDocument[]}
            meta={desktopBlock.meta}
            correlation={correlation}
          />
        </div>
      )
    }

    case 'topic': {
      const urls = desktopBlock.collection.map((d) => d.key)
      const blocks = urls.map((url) => screenDocuments[url])

      return (
        <div
          key={blockKey}
          className={makeClassName(
            blockClasses.concat([[styles[blockType], !!styles[blockType]]])
          )}
        >
          <TopicBlock
            blocks={blocks as ScreenDocument[]}
            correlation={correlation}
          />
        </div>
      )
    }

    case 'rotation': {
      const rotationDocument = screenRotationDocuments[url]
      const rotationUrls = rotationDocument.collection.map((d) => d.key)
      const randomIndex = isRendered
        ? randomIntFromInterval(0, rotationUrls.length - 1)
        : 0
      const currentRotationUrl = rotationUrls[randomIndex]
      const pickedDocument = screenDocuments[
        currentRotationUrl
      ] as ScreenDocument

      const customBlock = {
        title: null,
        uuid: 'customBlock',
        type: pickedDocument.layout,
        bg_image: null,
        published_at: pickedDocument.datetime,
        url: pickedDocument.url,
        collection: [{ key: pickedDocument.url, origin: ['screen'] }]
      }

      return (
        <RenderBlock
          desktopBlock={customBlock}
          correlation={correlation}
          origin={origin}
          screenDocuments={screenDocuments}
          currentUrl={currentUrl}
        />
      )
    }

    case 'signal_subscription':
    case 'brief_subscription': {
      return (
        <div
          key={`${blockKey}-${url}`}
          className={makeClassName(
            blockClasses.concat([[styles[blockType], !!styles[blockType]]])
          )}
        >
          <BriefSubscriptionBlock correlation={correlation} />
        </div>
      )
    }

    case 'brief': {
      return (
        <div
          key={`${blockKey}-${url}`}
          className={makeClassName(
            blockClasses.concat([[styles[blockType], !!styles[blockType]]])
          )}
        >
          <BriefBlock
            block={block as BriefDocument}
            correlation={correlation}
          />
        </div>
      )
    }

    case 'rich_promo': {
      return (
        <div
          key={`${blockKey}-${url}`}
          className={makeClassName(
            blockClasses.concat([[styles[blockType], !!styles[blockType]]])
          )}
        >
          <SupportBlock block={block} correlation={correlation} />
        </div>
      )
    }

    default: {
      return (
        <div
          key={blockKey}
          className={makeClassName([
            [styles.block, true],
            [styles.unknown, true]
          ])}
        />
      )
    }
  }
}

interface SectionProps {
  blocks: DesktopBlock[]
  currentUrl?: string
}

export const Section: React.FC<SectionProps> = ({ blocks, currentUrl }) => {
  const screenDocuments = useSelector(getScreenDocuments).byId

  const correlation = `is1to${blocks.length}` as keyof ImageUrls
  const classes = [
    [styles.root, true],
    [styles.isWide, blocks.length === 1]
  ]

  if (blocks.length === 3 && blocks[0].type === 'news') {
    blocks[0].featured = true
  }

  return (
    <div className={makeClassName(classes)}>
      {blocks.map((block) => (
        <RenderBlock
          key={block.collection[0].key}
          desktopBlock={block}
          correlation={correlation}
          screenDocuments={screenDocuments}
          currentUrl={currentUrl}
        />
      ))}
    </div>
  )
}
