import React, { useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { BookLandingDocument, ImageUrls } from '@client/types'
import { useBannerTrack } from '@hooks/useBannerTrack'
import { BlockTitle } from '@routes/Screen/BlockTitle'
import { makeClassName } from '@utils/makeClassName'

import { Meta, MetaItem, Tag } from '@meduza/ui-kit-2'

import styles from './BookLandingBlock.module.css'

interface BookLandingBlockProps {
  correlation: keyof ImageUrls
  block: BookLandingDocument
  origin: string
}

export const BookLandingBlock: React.FC<BookLandingBlockProps> = ({
  block,
  origin,
  correlation
}) => {
  const bannerTrackRef = useBannerTrack(block, origin)

  const [fallback, setFallback] = useState(false)

  useEffect(() => {
    setFallback(!window.HTMLPictureElement)
  }, [])

  const {
    tag,
    image: { base_urls: baseUrls, optimised_urls: optimisedUrls },
    meta
  } = block

  const [ref, inView, entry] = useInView({
    threshold: 0.1,
    triggerOnce: true
  })

  const [visible, setVisible] = useState(false)

  useEffect(() => {
    if (entry && entry.boundingClientRect && entry.boundingClientRect.y < 0) {
      setVisible(true)
    }
  }, [entry])

  useEffect(() => {
    if (inView) {
      setVisible(true)
    }
  }, [inView])

  const theme = block.theme.text_color === '0,0,0' ? 'isDark' : 'isLight'

  const cssProps = {
    '--bgColor': `rgb(${block.theme.background_color || '0, 0, 0'})`
  } as React.CSSProperties

  return (
    <article
      ref={bannerTrackRef}
      className={makeClassName([
        [styles.root, true],
        [styles[theme], true],
        [styles[correlation], !!styles[correlation]]
      ])}
      style={cssProps}
    >
      <div className={styles.container} ref={ref}>
        <div className={styles.imageWrap}>
          {visible && (
            <>
              <div className={styles.image}>
                <picture className={styles.picture}>
                  <source
                    type="image/webp"
                    srcSet={`${optimisedUrls[correlation]} 2x`}
                  />

                  <source
                    type="image/png"
                    srcSet={`${baseUrls[correlation]} 2x`}
                  />
                  <img
                    src={
                      fallback
                        ? baseUrls[correlation]
                        : 'data:image/gif;base64,R0lGODlhAQABAPAAAPLy8gAAACH5BAAAAAAALAAAAAABAAEAAAICRAEAOw=='
                    }
                    alt={block.title}
                  />
                </picture>
              </div>
            </>
          )}
        </div>
        <div className={styles.contentWrap}>
          <div className={styles.content}>
            {tag && (
              <div className={styles.tag}>
                <Tag size="small" theme="custom">
                  {tag.name}
                </Tag>
              </div>
            )}
            <div className={styles.title}>
              <BlockTitle
                block={block}
                correlation={correlation}
                styleContext="isInEpisode"
              />
            </div>

            <div className={styles.footer}>
              <Meta>
                {meta.data?.components.map((component) => {
                  switch (component.type) {
                    case 'string': {
                      return (
                        <MetaItem bullets key={component.id}>
                          <>{component.text}</>
                        </MetaItem>
                      )
                    }
                    default: {
                      return null
                    }
                  }
                })}
              </Meta>
            </div>
          </div>
        </div>
      </div>
    </article>
  )
}
