import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getLang } from '@client/selectors/currentUserSelectors'
import { AudioPanel } from '@common/AudioPlayer/AudioPanel'
import { AudioPanelTypes, PlayerStatus } from '@common/AudioPlayer/constants'
import { Link } from '@common/Link'
import { RenderBlocks } from '@common/RenderBlocks'
import { getPlayingEpisodeProps } from '@selectors/audioPlayerSelectors'
import { requestClose } from '@store/AudioPlayer/audioPlayerActions'
import { makeClassName } from '@utils/makeClassName'

import { Button, Dropdown, Menu } from '@meduza/ui-kit-2'

import styles from './AudioPlayerMobile.module.css'

/*
 * Плеер для мобилы
 */

interface AudioPlayerMobileProps {
  url: string
}

export const AudioPlayerMobile: React.FC<AudioPlayerMobileProps> = (
  {
    // url
  }
) => {
  const dispatch = useDispatch()
  const playingEpisode = useSelector(getPlayingEpisodeProps)
  const lang = useSelector(getLang)

  const [open, setOpen] = useState(false)

  if (!playingEpisode || playingEpisode?.data?.status === PlayerStatus.CLOSED) {
    return <div />
  }

  const {
    data: { image, blocks, audio },
    episodeUrl
  } = playingEpisode

  const handleCloseClick = () => {
    setOpen(false)
    dispatch(requestClose({ episodeUrl }))
  }

  const podcast = audio.podcast

  const coverImage =
    image.mobile_cover ||
    audio?.coverUrl ||
    image.normal_retina_url ||
    image.base_urls?.is1to4

  return (
    <div className={styles.root}>
      <button
        className={styles.control}
        onClick={() => setOpen(true)}
        type="button"
      >
        <img src={coverImage} alt={audio.title} />
        <div className={styles.animation}>
          <div className={styles.line} />
          <div className={styles.line} />
          <div className={styles.line} />
          <div className={styles.line} />
          <div className={styles.line} />
        </div>
      </button>

      <div
        className={makeClassName([
          [[styles.container], true],
          [[styles.isVisible], open]
        ])}
      >
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
        <div
          className={styles.overlay}
          onClick={() => setOpen(false)}
          tabIndex={-1}
          role="button"
        />

        <div className={styles.player}>
          <div className={styles.buttonContainer}>
            <button
              className={styles.dismissButton}
              onClick={() => setOpen(false)}
              type="button"
            />
          </div>

          <div className={styles.image}>
            <img src={coverImage} alt={audio.title} />
          </div>
          <div className={styles.blocks}>
            {blocks &&
              blocks.map((block) => {
                return (
                  <RenderBlocks
                    key={block.id}
                    block={block}
                    styleContext={[
                      'isInDynamicBlock',
                      'isInPodcastPlayer',
                      'simple'
                    ]}
                  />
                )
              })}
          </div>
          <AudioPanel
            episodeUrl={episodeUrl}
            lang="ru"
            type={AudioPanelTypes.INSTANT}
            styleContext="isInInstantPlayer"
            image={coverImage}
            setOpen={setOpen}
          />

          <div className={styles.footer}>
            <div>
              <Link to={`/${episodeUrl}`} onClick={() => setOpen(false)}>
                {lang === 'ru' ? 'Открыть материал' : 'Go to story'}
              </Link>
            </div>
            <div className={styles.podcast}>
              <Link
                to={`/${podcast.url}`}
                onClick={() => setOpen(false)}
                className={styles.podcastLink}
              >
                {lang === 'ru' ? 'Все эпизоды' : 'All episodes'}
              </Link>
            </div>
            {podcast.media_platform_blocks && (
              <div className={styles.subscribe}>
                <Dropdown styleContext="isInAudioPanel">
                  <Button size="auto" theme="ghost">
                    <u>{podcast.media_platforms_button_text}</u>
                  </Button>

                  <Menu>
                    <ul>
                      {podcast.media_platform_blocks.map((item) => (
                        <li key={`menu-${item.title}`}>
                          <a href={item.url} target="_blank" rel="noreferrer">
                            {item.title}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </Menu>
                </Dropdown>
              </div>
            )}
            <button
              className={styles.closeButton}
              onClick={handleCloseClick}
              type="button"
            >
              {lang === 'ru' ? 'Закрыть плеер' : 'Close'}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

AudioPlayerMobile.whyDidYouRender = true
