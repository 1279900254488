import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { PopoverBanner } from '@client/components/Common/PopupBanner/PopupBanner.types'
import { BannerItem } from '@client/types'
import { BannerPosition } from '@client/types/bannerTypes'
import { trackBannerView } from '@store/Analytics/analyticsActions'

// Хук для аналитики bannerView (отправка в GA)

export const useBannerViewAnalytics = ({
  inView,
  banner,
  position,
  dispatch
}: {
  inView: boolean
  banner: BannerItem | PopoverBanner
  position: BannerPosition
  dispatch: ReturnType<typeof useDispatch>
}) => {
  useEffect(() => {
    if (inView && banner) {
      const pathname = window.location.pathname
      const bannerId = banner.id || 'null'
      dispatch(
        trackBannerView({
          pathname,
          bannerPosition: position,
          bannerId
        })
      )
    }
  }, [dispatch, inView, banner, position])
}
